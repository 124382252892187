#werbung {
    overflow-x: hidden;
    h2 {
        color: #9e9e9e;
        margin: 30px 50px;
        font-size: 26px;
        padding: 0;
        

    }
    p {
        font-size: 18px;
        margin: 20px 50px 20px;

        #pdf {
            text-decoration: none;
            color: #1a325b;
            
            font-weight: 500;
            font-size: 18px;
            
        }
        .mail {
            text-decoration: none;
            color: #1a325b;
        }
    }

    #banner {
        background-color: #f6f6f6;
        width: 100%;
        #banner_inhalt {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 50px;

            .banner_part {
                width: 160px;
                text-align: center;
                padding-top: 10px;
                p {
                    font-family: roboto, sans serif;
                    font-weight: 500;
                    margin: 0;
                    margin-bottom: 10px;
                }
            }
        }
    }
    #werbung_img {
        width: 93%;
        margin: 20px auto;
        border: 2px solid #aeaeae;

        img {
            width: 100%;
            display: block;
        }
    }

    #credits {
        color: #b6b6b6;
        float: right;
        font-size: 16px;
    }

}

@media screen and (max-width: 1099px) {
    #werbung #banner #banner_inhalt .banner_part {
        width: 30%;
    }
}

@media screen and (max-width: 779px) {
    #werbung #banner #banner_inhalt .banner_part {
        width: 160px;
    }
}

@media screen and (max-width: 470px) {
    
    #werbung {
        #banner {
            #banner_inhalt {
                .banner_part {
                   margin: 0 auto;
                }
            }
        }
    }
    
}