#fachexpertise {
    h2 {
        padding-top: 30px;

    }
    p {
        font-size: 18px;

        padding: 0 $space;

        a {
            text-decoration: none;
            color: #1a325b;
        }
    }

}