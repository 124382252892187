#rechtliche_aspekte {
    overflow-x: hidden;

    h2 {
        padding-top: 30px;

    }
    #rechtliches {
        padding: 0px $space;
        h4 {
            font-family: roboto, sans serif;
            font-weight: 500;
            font-size: 20px;
        }
        p {
            font-size: 18px;
            margin: 18px 0;
        }
        ul{
            margin-left: 32px;
            font-size: 18px;
        }
        a {
            text-decoration: none;
            color: #1a325b;
        }
    }
}