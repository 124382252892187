#navigation{ 
    height: 60px;
    background: rgba(164, 163, 163, 0.1);
    
    
    .inner {
        position: relative;
    }
    
    ul{
        padding: 0;

        &:after{
            clear: both;
            content: " ";
            display: block;
            font-size: 0;
            height: 0;
            visibility: hidden;
        }
        li { 
            list-style: none;
            float:left;
            position: relative;
            width: 15%;

            a,a:link,a:visited,a:active {
                text-decoration: none;
                display: block;
                color: $color-midgrey;
                padding: $space; 
                font-size: 18px;
                text-transform: uppercase;
                line-height:18px;
                min-height: 20px;
                cursor: pointer;
                font-family: roboto, sans serif;
                font-weight: 500;

                &:hover{
                    color: $color-midgrey;
                }

                &.selected{
                    color: $color-blue
                }
            }
            &:hover{
                a,a:link,a:visited,a:active {
                    color: $color-blue;
                    /*box-shadow: 0 10px 10px #999999;*/
                }
                li {
                    a,a:link,a:visited,a:active {
                        background: none;
                        box-shadow: none;
                    }
                }
                > ul {
                    visibility: visible;
                }
            }
            }
        /* pulldown */
        ul{
            display: inline;
            visibility: hidden;
            position: absolute;
            padding: 0;
           background: #f6f6f6;
            width: 15rem;
            padding: 7px 0;

/*            box-shadow: 0 10px 10px #999999;*/
            z-index: 1800;
            left: 0;

            li {
                width: auto;
                
                a,a:link,a:visited,a:active{
                    padding: 7px 0 7px $space;
                    font-size: 18px;
                    
                    font-weight: 300;
                    color: $color-midgrey;
                    text-transform: none;
                    min-height: auto;
                    &.selected, &:hover{
                        font-family: roboto, sans serif;
                        font-weight: 500;
                        box-shadow: none;
                        color: $color-blue;
                    }
                }
                float: none;
            }
       }
    }
    .socialmedia {
        float: right;
        margin-top: 12px;
        margin-left: $space;
        position: relative;
        width: 35px;
        height: 35px;

        svg {
            width: 30px;
            height: 30px;
            fill: $color-blue;
        }
    }
}



/*


#nav_inhalt {
    margin-left: 40px;
    width: calc(90% - 50px);
    display: inline-block;
    font-size: 0;

}

.nav_part{
    display: inline-block;
    padding: 18px 0;
    width: 17%;
    z-index: 50;
    position: relative;
    font-size: 0 !important;

    a {
        text-decoration:none;
    }

    .subdown {
        display: none;
        float: right;
        transition-duration: .3s;
    }
    &:hover {

        .nav_element {
            color: #9e9e9e
        }
        .submenu {
            display: block;
        }
    }
}


.nav_element {
    padding-left: 20px;
    color: #003a5c;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 20px;


}

.submenu {
    display:none;
    position: absolute;
    background-color: #f3f3f4;
    width: calc(100% - 30px);
    padding: 5px 15px;
    margin-top: 18px;
    z-index: 50;
    border: 1px solid #e7e7e9;

    .sub_nav_element {
        display: inline-block;
        width: 100%;

        a {
            font-family: 'Abel', sans-serif !important;
            color: #003a5c;
            text-decoration: none;
            font-size: 18px;

            &:hover {
                color: #9e9e9e;
            }
        }
    }


}

.selected {
    color: #9e9e9e !important;
}


.SM {
    display: inline-block;
    float: right;
    margin-top: 8px;
    right: 50px;
    position: relative;
    width: 8%;

    a {
        font-size: 33px;
        color: #003a5c;
        float: right;
        
        img {
            width: 32px;
            padding: 10px;
            padding-top: 5px;
        }
    }


}
*/