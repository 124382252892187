#newsletter {
    overflow-x: hidden;
    margin-bottom: 20px;

    h2 {
        padding-top: 30px;

    }
    #anmeldung {
        background-color: #f3f3f4;
        
        padding: 10px $space;;
        h3 {
            font-family: roboto, sans serif;
            font-weight: 500;
            font-size: 20px;
        }
        p {
            font-size: 18px;
            margin: 5px 0;
        }
        form {
            margin: 10px 0;
        }
        input[type=text], input[type=email] {
            margin: 10px 0;
            width: calc(100% - 20px);
            border-radius: 0px;
            border: 1px solid #1a325b;
            height: 30px;
            color: #1a325b;
            padding: 5px;
            background-color: #f3f3f4;
        }
        input[type=submit] {
            margin: 10px 0;
            border-radius: 0px;
            border: 2px solid #1a325b;
            color: #1a325b;
            font-size: 18px;
            width: 25%;
            padding: 7px 5px;
            font-family: roboto, sans serif;
            font-weight: 500;
            height: 50px;
            
            &:hover {
                color: white;
                background-color: #1a325b;
            }
        }
        
    }
}

@media screen and (max-width: 1000px) {
 
    #newsletter {
        #anmeldung {
            input[type=submit] {
                width: 50%;
            }
        }
    }
    
}
@media screen and (max-width: 400px) {
 
    #newsletter {
        #anmeldung {
            input[type=submit] {
                width: calc(100% - 20px);
            }
        }
    }
    
}

