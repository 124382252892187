#partner {
    h2 {
       padding-top: 30px;
    }
    #partners {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        flex-direction: row;


        .partner {
            width: 30%;
            margin: 15px 0;
            background-color: #f6f6f6;

            h3 {
                font-family: roboto, sans serif;
                font-weight: 500;
                font-size: 18px;
                padding: 15px;
                padding-left: $space;
            }
            .img-con {
                background-color: white;
                max-width: calc(100% - 40px);
                margin: 0 auto;
                img {
                    height: 100px;
                    max-width: 100%;
                    margin: 0 auto;
                    padding: 15px 0;
                    display: block;
                }
            }
            p {

                margin: $space;
                font-size: 18px;

                strong {
                    
                    font-weight: 500;
                }
            }
            .partner_hidden {
                height: 102px;
                overflow: hidden;
                text-overflow: ellipsis;
/*                content: "";*/
/*                white-space: nowrap;*/
                position:relative;
            }
            .partner_hidden:before {
                content:"";
                width:100%;
                height:100%; 
                position:absolute;
                left:0;
                top:0;
                background:linear-gradient(transparent 150px, #f6f6f6);
            }
            .more_partner {
                display: block;
                padding:  0 $space 5px;
                cursor: pointer;
                color: $color-blue;
                
                &:hover {
                    color: $color-midgrey;
                }
                
                img {
                    width: 15px;
                    display: inline-block;
                }
                p {
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                    padding-left: 3px;
                }
            }

        }
    }
}

@media screen and (max-width: 1000px) {
 
    #partner {
        #partners {
            .partner {
                width: 48%;
            }
        }
    }
    
}

@media screen and (max-width: 600px) {
 
    #partner {
        #partners {
            .partner {
                width: 100%;
            }
        }
    }
    
}

