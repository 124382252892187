#portrait {
    overflow-x: hidden;
    margin-bottom: 30px;
    h2 {
        padding-top: 30px;

    }
    p {
        padding: 5px $space;
        font-size: 18px;

        a {
            text-decoration: none;
            color: #1a325b;
        }
        strong {
            
            font-weight: 500;
        }
    }
}