#datenschutz {

    #top {
        color: #9e9e9e;
        padding: 30px $space;
        font-size: 26px;

    }
    p {
        font-size: 18px;
        margin: 10px $space;
        text-decoration: none;
    }

    a {
        text-decoration: none;
        color: #1a325b;
    }
    h2 {
        padding: 5px $space;
    }

    h3 {
        margin: 20px $space;
    }
    ul {
        margin-left: 50px;
    }
}