.boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    article {
        background-color: #f6f6f6;
        min-width: 350px;
        width: 48%;

        display: inline-block;
        top: 0;
        margin: 10px 0px;

        .info {
            font-size: 18px;
            color: #797979;
            padding: 10px 20px;
            height: 25px;
            overflow: hidden;
            text-overflow: "...";
            white-space: nowrap;
        }

        h3 {
            a{
                text-decoration: none;
                color: #20426c;
                font-family: roboto, sans serif;
                font-weight: 500;
                letter-spacing: 1px;
                font-size: 20px;
                padding: 0 20px;
            }
        }

        .logo {
            &.yellow{
                background-color: $color-yellow;
            }

            background-color: #13325e;
            padding: 0;

            img {
                height: 105px;
                display:block;
                margin: 10px auto;
                padding: 20px 0;            
            }
        }

        p {
            font-size: 18px;
            padding: 20px;
            font-weight: 300;
            line-height: 25px;

            strong {
                
                font-weight: 500;

            }            

            a {
                text-decoration: none;
                color: #1a325b;
            }
        }
        br { margin: 0 0 7px 0;}
    }
}

@media screen and (max-width: 808px) {
    .boxes {
        article {
            width: 100%;
            min-width: unset;
        }
    }
}