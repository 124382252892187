footer {
    background: url("footer2.svg");
    height: 310px;
    /*opacity: 0.9;*/
    position: relative;
    margin: 0 auto;
    overflow: hidden;

    #footer_top{
        height: 220px;
        overflow: hidden;

        #menu_footer_mob {
            display: none;
        }

        .inner {
            position: relative;
        }

        ul{
            padding: 0;
            margin-top: 90px;

            li { 
                list-style: none;
                float:left;
                position: relative;
                width: 15%;

                a,a:link,a:visited,a:active {
                    text-decoration: none;
                    display: block;
                    color: $color-white;
                    padding: 0 $space; 
                    font-size: 18px;
                    text-transform: uppercase;
                    line-height:18px;
                    min-height: 20px;
                    cursor: pointer;
                    font-family: roboto, sans serif;
                    font-weight: 500;

                    &:hover{
                        color: $color-midgrey;
                    }
                }
                &:hover{
                    li {
                        a,a:link,a:visited,a:active {
                            background: none;
                            box-shadow: none;
                        }
                    }
                }
            }
            /* pulldown */
            ul{
                margin-top: 0;
                display: inline;
                position: absolute;
                padding: 0;
                width: 15rem;
                padding: 4px 0;

                z-index: 60;
                left: 0;

                li {
                    width: auto;

                    a,a:link,a:visited,a:active{
                        padding: 7px 0 7px $space;
                        font-size: 16px;
                        font-weight: 300;
                        color: $color-white;
                        text-transform: none;
                        min-height: auto;
                    }
                    float: none;
                }
            }
        }
        .socialmedia {
            float: right;
            margin-left: $space;
            position: relative;
            width: 30px;
            height: 30px;

            svg {
                width: 30px;
                height: 30px;
                fill: $color-white;
            }
        }
    }        

    #footer_bottom{
        height: 95px;
        color: $color-white;

        img {
            float: left;
            height: 45px;
            margin: 20px $space;
        }
        p{
            margin: 30px $space;  
            float: left;

            a {
                color: white;
                text-decoration: none;

                &:hover {
                    color: $color-midgrey;
                }
            }

            &.fr{
                float: right;
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 1475px) {
    #footer_top {
        margin: 0 20px;
    }
    #footer_bottom {
        margin: 0 20px;
    }
}

@media screen and (max-width: 1060px) {
    footer {
        #footer_top {
            ul li {
                width: 17%;
            }
        }
        #footer_bottom {
            p {
                margin: 30px 0;
            }
        }
    }
}

@media screen and (max-width: 990px) {
    footer {
        #footer_bottom {
            /*              p {
                              margin: 20px 10px 0;
                              
                              &.fr {
                                  float: left;
                                  margin: 5px 10px;
                                  
                                  
                              }
                          }*/

        }
    }   
}

@media screen and (max-width: 930px) {
    footer {
        #footer_top {
            #menu_footer {
                display: none;

            }
            .socialmedia {
                display: none;
            }
            #menu_footer_mob {
                display: block;
                padding-top: 65px;
                max-width: 600px;
                /*margin: 0 auto;*/
                /*margin-left: $space;*/


                table {
                    width: 100%;
                    padding-left: $space;
                    tr:nth-child(3) {
                        display: none;

                    }
                    td {
                        padding: 10px 0;
                        width: 33%;
                        /*text-align: center;*/

                        &:nth-child(2) {
                            padding-left: 10px;
                        }

                        &:nth-child(3) {
                            width: 35px;
                            padding-left: 10px;

                        }
                        a {
                            color: white;
                            text-decoration: none;
                            /*padding: 0 10px;*/
                        }
                        .socialmedia_mob {
                            width: 35px;
                            height: 35px;


                            svg {
                                width: 35px;
                                height: 35px;
                                fill: $color-white;
                            }
                        }
                    }
                }

            }
        }
        #footer_bottom {

            img {
                /*margin: $space 0;*/
            }
        }

    }
}

@media screen and (max-width: 870px) {
footer {
    #footer_bottom {

        img {
            /*margin: $space 0;*/
        }

        p {
            margin: 20px 10px 0;

            &.fr {
                float: left;
                margin: 5px 10px;


            }
        }
    }
}
}

@media screen and (max-width: 722px) {
    footer {
        #footer_bottom {
            p {
                display: none;
                &.fr {
                    display: block;
                    margin: 30px 0;
                    float: right;
                }
            }
        }
    }
}

@media screen and (max-width: 392px) {

    footer {
        height: 330px;

        #footer_top {
            height: 225px;
        }
        #footer_bottom {
            text-align: center;
            img {
                margin: 15px auto 5px;
                display: block;
                float: none;
            }
            p.fr {
                display: block;
                float: none;
                margin: 10px;
            }
        }
    }

}