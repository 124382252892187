main {
    position: relative;
    margin-top: 165px;
    margin-bottom: 100px;
    min-height: calc(100vh - 475px);
}
#portals{
    padding: 40px 0;
}
#newsletters{
    padding: 40px 0;
}


@media screen and (max-width: 1000px) {
    main {
        margin-top: 100px;
        min-height: calc(100vh - 440px);
    }
}

   @media screen and (max-width: 400px) {
       main {
           min-height: calc(100vh - 460px);
       }
   }
