#carousel {
    width: 100%;
    height: 400px;
    max-height: 650px;
    position: relative;
    overflow: hidden;
    max-width: unset;

    .c_image {
        height: 100%;
        width: 100%;
        position: absolute;
        transition:all 1.5s ease;
        display:none;
        background: url("HeaderImage2.png");
        background-size: cover;
        background-position: center;
    }
    .center{
        left:0;
        display: inline-block;
    }
    .right{
        left:100%;
        display: inline-block;
    }
    .left{
        left:-100%;
        display: inline-block;
    }
    .layer {
        width: 100%;
        height: 100%;
        background-color: rgba(19, 50, 94, 0.7);
        text-align: center;
        font-family: Roboto,sans-serif; 

        .layerText {
            width: calc(100% - 80px);
            top: 50%;
            margin: 0 auto;
            position: relative;
            transform: translateY(-50%);
            h1 {
                position: relative;
                z-index: 10;
                color: #fff;
                font-size: 35px;
                letter-spacing: .12em;
                
                /*            padding-top: 40px;*/
                margin-bottom: 10px;
                
                strong {
                    font-family: roboto, sans serif;
                    font-weight: 700;
                }
                
                
            }
            h2 {
                position: relative;
                z-index: 10;
                font-family: roboto, sans serif;
                font-weight: 500;
                color: #ffd567;
                font-size: 25pt;
                letter-spacing: 0.1em;
                margin-bottom: 20px;
                
                strong {
                    font-weight: 500;
                }
            }
            p {
                position: relative;
                z-index: 10;
                color: #fff;
                font-size: 35px;
                font-family: roboto, sans serif;
                font-weight: 500;
                letter-spacing: .02em;
                margin-bottom: 15px;
            }
            .carousel_link {
                position: relative;
                z-index: 10;
                padding: 5px 30px;
                border: 3px solid #ffd567;
                font-size: 18px;
                font-family: roboto, sans serif;
                font-weight: 500;
                cursor: pointer;
                text-decoration: none;
                color: #ffd567;
                letter-spacing: 0.1em;

                &:hover {
                    background: rgba(0, 0, 0, 0.3);

                }

            }
        }
    }

    .arrows {
        z-index: 30;
        position: absolute;
        font-size: 40px;
        top: 50%;
        transform: translateY(-50%);
        color: #1a325b;
    }
    .back {
        left: 50px;
    }
    .next {
        right: 50px;
    }
}

@media screen and (max-width: 1475px) {
    #carousel {
        margin: 0;
    }
}
@media screen and (max-width: 740px) {
    #carousel {
        .layer {
            .layerText {
                h1 {
                    font-size: 30px;
                }
                h2 {
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 370px) {
    #carousel {
        .layer {
            .layerText {
                h1 {
                    font-size: 25px;
                }
                h2 {
                    font-size: 20px;
                }
            }
        }
    }
}