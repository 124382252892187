#fbHeader {
    height: 32px;
    width: 32px;
    background-color: #13325e;
    -webkit-mask: url(fb-logo.svg) no-repeat center;
    mask: url(fb-logo.svg) no-repeat center contain;
    mask-size: contain;
    margin: 5px;
}

#lnHeader {
    height: 32px;
    width: 32px;
    background-color: #13325e;
    -webkit-mask: url(ln-logo.svg) no-repeat center;
    mask: url(fb-logo.svg) no-repeat center contain;
    mask-size: contain;
    margin: 5px 0 5px 15px;
}

/*#overlay {
    display: none;
    background-color: rgba(19,50,94,.9);
    position: absolute;
    z-index: 40;
    height: calc(100vh - 110px);
    top: 110px;
    width: 100vw;

}

.invisible {
    opacity: 0;

}

.overflowHidden {
    overflow: hidden;
}*/
/**********MAIN****************************************************************/
/*
main{
    position: relative;
    margin-top: 165px;
    min-height: calc(100vh - 475px);

}
#content_limiter{

    max-width:1400px;
    height:100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

}*/
/**********Portale************************/
/*
#portale {
    padding: 40px;
    margin-bottom: 20px;


    h2 {
        color: #9e9e9e;
        margin-bottom: 30px;
        font-size: 26px;
        padding-left: 20px;
        font-weight: 400;


    }
    #portals {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .portal {
            background: rgba(164, 163, 163, 0.1);
            min-width: 350px;
            width: 48%;

            display: inline-block;
            top: 0;
            margin: 10px 0px;

            .portal_info {
                font-size: 18px;
                color: #797979;
                padding: 10px 20px;
            }

            .portal_link {
                text-decoration: none;
                color: #20426c;
                font-family: Roboto,sans-serif;
                font-weight: 700;
                letter-spacing: 1px;
                font-size: 20px;
                padding: 0 20px;
            }

            .portal_logo_con {

                background-color: #13325e;
                padding: 0;

                .portal_logo {
                    height: 105px;
                    display:block;
                    margin: 10px auto;
                    padding: 20px 0;            
                }
            }

            p {
                font-size: 18px;
                padding: 20px;
                font-weight: 400;

                strong {
                    font-family: 'Roboto', sans-serif;
                    font-weight: 700;
                    
                }            

                a {
                    text-decoration: none;
                    color: #1a325b;
                }
            }
            br { margin: 0 0 7px 0;}
        }
        #huetten_online {
            float: right;

        }
    }
}
*/
/**********Newsletter***********************/
/*
#news_part {

    padding: 60px 40px 40px;


    h2 {
        color: #9e9e9e;
        margin-bottom: 30px;
        font-size: 26px;
        padding-left: 20px;
        font-weight: 400;
    }
    #news {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .news {
            background: rgba(164, 163, 163, 0.1);
            width: 48%;
            display: inline-block;
            top: 0;
            min-width: 350px;
            margin: 10px 0px;
            position: relative;

            .news_info {
                font-size: 18px;
                color: #797979;
                padding: 10px 20px;
            }

            .news_link {
                text-decoration: none;
                color: #20426c;
                font-weight: 700;
                letter-spacing: 1px;
                font-size: 20px;
                padding: 0 20px;
            }

            #gipfelnews_logo_con {
                background-color: #fbd65f;
            }

            .news_logo_con {
                background-color: #13325e;
                padding: 0;

                .news_logo {
                    height: 105px;
                    display:block;
                    margin: 10px auto;
                    padding: 40px 0;            
                }
            }

            p {
                font-size: 18px;
                padding: 20px;

                strong {
                    font-weight: 700;
                    font-size: 18px;
                }

                a {
                    text-decoration: none;
                    color: #1a325b;
                }
            }
        }
        #gipfelbuch {
            float: right;

        }
    }
}
*/
/**********Werbung*************************************************************/

/*#werbung {
    overflow-x: hidden;
    h2 {
        color: #9e9e9e;
        margin: 30px 50px;
        font-size: 26px;
        padding-left: 25px;

    }
    p {
        font-size: 18px;
        margin: 20px 50px 20px;

        #pdf {
            text-decoration: none;
            color: #1a325b;
            font-weight: 700;
            font-size: 18px;
            
        }
        .mail {
            text-decoration: none;
            color: #1a325b;
        }
    }

    #banner {
        background: rgba(164, 163, 163, 0.1);
        width: 100%;
        #banner_inhalt {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 50px;

            .banner_part {
                width: 160px;
                text-align: center;
                padding-top: 20px;
                p {
                    font-weight: 700;
                    margin: 20px 0;
                }
            }
        }
    }
    #werbung_img {
        width: 93%;
        margin: 20px auto;
        border: 2px solid #aeaeae;

        img {
            width: 100%;
            display: block;
        }
    }

    #credits {
        color: #b6b6b6;
        float: right;
        font-size: 16px;
    }

}*/

/**********Fachexpertise*******************************************************/

/*#fachexpertise {
    h2 {
        color: #9e9e9e;
        padding: 30px 75px;
        font-size: 26px;

    }
    p {
        font-size: 18px;

        padding: 0 50px;

        a {
            text-decoration: none;
            color: #1a325b;
        }
    }

}*/

/**********Pressespiegel*******************************************************/

/*#pressespiegel {
    //display: flex;
    overflow-x: hidden;

    h2 {
        color: #9e9e9e;
        padding: 30px 75px;
        font-size: 26px;

    }
    #artikels {
        margin: 0 50px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        flex-direction: row;


        .artikel {
            width:30%;
            margin: 10px 0;
            padding: 10px 0;
            background: rgba(164, 163, 163, 0.1);


            .infos {
                color: #797979;
                font-size: 18px;
                padding: 5px;
                margin-left: 10px;
            }
            h3 {
                color: #000;
                margin-left: 15px;
                font-weight: 700;
                margin-bottom: 10px;
                letter-spacing: 0.5px;
                line-height: 25px;
                min-height: 75px;
                font-size: 18px;

            }
            img {
                width: 100%;

            }
            .desc {
                padding: 5px 15px;
                font-size: 18px;
            }
            a {
                text-decoration: none;
                color: #1a325b;
                padding: 5px 15px;
                font-size: 18px;

                .pdf {
                    width: 17px;
                    margin-right: 5px;
                }
            }

        }        
    }
}*/

/**********Presse-Downloads****************************************************/

/*#pressedownloads {
    overflow-x: hidden;
    h2 {
        color: #9e9e9e;
        padding: 30px 75px;
        font-size: 26px;

    }
    #artikels {
        margin: 0 50px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        flex-direction: row;

        .artikel {
            width:30%;
            margin: 10px 0;
            padding: 10px 0;
            background: rgba(164, 163, 163, 0.1);

            .infos {
                color: #797979;
                font-size: 18px;
                padding: 5px;
                margin-left: 10px;
            }
            h3 {
                color: #000;
                margin-left: 15px;
                font-weight: 700;
                margin-bottom: 10px;
                font-size: 18px;

            }
            img {
                width: 100%;
            }
            .desc {
                padding: 5px 15px;
                font-size: 18px;
            }
            a {
                text-decoration: none;
                color: #1a325b;
                padding: 5px 15px;
                font-size: 18px;

                .pdf {
                    width: 17px;
                    margin-right: 5px;
                }
            }
        }        
    }
}*/

/**********bergportal-team*****************************************************/

/*#bergportal_team {
    overflow-x: hidden;
    h2 {
        color: #9e9e9e;
        padding: 30px 75px;
        font-size: 26px;

    }
    #personen {
        margin: 0 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .person {
            width:30%;

            margin: 10px 0;
            padding: 10px 0;
            background: rgba(164, 163, 163, 0.1);
            h3 {
                color: #000;
                margin-left: 15px;
                font-weight: 700;
                margin-bottom: 10px;
                display: inline-block;
            }
            .lna {
                float: right;
                padding: 0 5px;
                font-size: 25px;
            }
            .img {
                width: 100%;
                position: relative;
                img {
                    width: 100%;
                }
                .layerP {
                    position: absolute; 
                    z-index: 5;
                    background-color: RGBA(0, 0, 0, 0.3);
                    width: 100%;
                    height: calc(100% - 5px);
                    display: none;
                    p {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: white;
                        font-size: 20px;
                        width: 80%;
                        text-align: center;
                    }
                }
                &:hover {
                    .layerP {
                        display: block;
                    }
                }

            }
            .pos {
                color: #9f9f9f;
                padding: 5px 15px;
                font-size: 18px;
            }
            .contact {
                margin-left: 15px;
            }
            a {
                text-decoration: none;
                color: #1a325b;
                
                font-size: 18px;
                
                .lnTeam {
                    height: 25px;
                    width: 25px;
                    background-color: #13325e;
                    -webkit-mask: url(ln-logo.svg) no-repeat center;
                    mask: url(fb-logo.svg) no-repeat center contain;
                    mask-size: contain;
                    display: inline-block;
                    
                }
            }
            .tel::before {
                content: " | ";
                
                
                
            }
        }        
    }
}*/

/**********Portrait***********************/

/*#portrait {
    overflow-x: hidden;

    margin-top: 60px;
    h2 {
        color: #9e9e9e;
        padding: 30px 75px;
        font-size: 26px;

    }
    p {
        padding: 5px 50px;
        font-size: 18px;

        a {
            text-decoration: none;
            color: #1a325b;
        }
        strong {
            font-weight: 700;
        }
    }
}*/

/**********Geschichte***********************/
/*#geschichte {
    overflow-x: hidden;

    margin-top: 30px;
    margin-bottom: 20px;
    h2 {
        color: #9e9e9e;
        padding: 30px 75px;

    }
    p {
        padding: 5px 50px;
        font-size: 18px;
        strong {
            font-weight: 700;
        }
    }
}*/
/**********Partner*************************************************************/

/*#partner {
    h2 {
        padding: 30px 75px;
        color: #9e9e9e;
        font-size: 26px;
    }
    #partners {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        flex-direction: row;
        padding: 0 50px;

        .partner {
            width: 30%;
            margin: 15px 0;
            background: rgba(164, 163, 163, 0.1);

            h3 {
                font-weight: 700;
                font-size: 18px;
                padding: 15px;
                padding-left: 20px;
            }
            .img-con {
                background-color: white;
                img {
                    height: 100px;
                    max-width: 100%;
                    margin: 0 auto;
                    padding: 15px 0;
                    display: block;

                }
            }
            p {

                padding: 20px;
                font-size: 18px;

                strong {
                    font-weight: 700;
                }
            }

        }
    }
}*/
/**********Newsletter**********************************************************/

/*#newsletter {
    overflow-x: hidden;
    margin-bottom: 20px;

    h2 {
        color: #9e9e9e;
        padding: 30px 75px;
        font-size: 26px;

    }
    #anmeldung {
        background-color: #f3f3f4;
        margin: 0px 50px;
        padding: 10px 20px;;
        h3 {
            font-weight: 700;
            font-size: 20px;
        }
        p {
            font-size: 18px;
            margin: 5px 0;
        }
        form {
            margin: 10px 0;
        }
        input[type=text], input[type=email] {
            margin: 10px 0;
            width: 99%;
            border-radius: 0px;
            border: 1px solid #1a325b;
            height: 30px;
            color: #1a325b;
            padding: 5px;
            background-color: #f3f3f4;
        }
        input[type=submit] {
            margin: 10px 0;
            border-radius: 0px;
            border: 2px solid #1a325b;
            color: #1a325b;
            font-size: 17px;
            width: 25%;
            padding: 7px 5px;
            font-weight: 700;
            
            &:hover {
                color: white;
                background-color: #1a325b;
            }
        }
        
    }
}*/

/**********Spenden*************************************************************/

/*#spenden {
    overflow-x: hidden;
    margin-bottom: 20px;

    h2 {
        color: #9e9e9e;
        padding: 30px 75px;
        font-size: 26px;

    }
    #danke {
        font-weight: 700;
        font-size: 20px;
        padding: 0 50px;
    }
    p {
        font-size: 18px;
        padding: 0px 50px;

    }
    #daten {
        margin: 60px 50px 20px;
        background: rgba(164, 163, 163, 0.1);
        padding: 15px;

        p {
            margin: 10px 0;
            padding: 0;
        }
        strong {
            font-weight: 700;
        }

    }
}*/

/**********legate**************************************************************/

/*#legate {
    overflow-x: hidden;
    margin-bottom: 20px;

    margin-top: 60px;

    h2 {
        color: #9e9e9e;
        padding: 30px 75px 20px;
        font-size: 26px;

    }

    p {
        font-size: 18px;
        margin: 10px 50px;


        strong {
            font-weight: 700;
        }
        a {
            text-decoration: none;
            color: #1a325b;
        }
    }
}*/

/**********Impressum***********************************************************/

/*#impressum {
    overflow-x: hidden;

    h2 {
        color: #9e9e9e;
        padding: 30px 75px;
        font-size: 26px;

    }
    #imp {
        p {
            font-size: 20px;
            margin: 15px 0 ;
            padding: 0 50px;
            a {
                text-decoration: none;
                color: #1a325b;
            }
        }
        img {
            width: 300px;
            margin: 0 50px;
        }
        
        .credits {
            
            margin: 0 50px;
            a {
                text-decoration: none;
                color: #1a325b;
            }
            
        }
    }
}*/

/**********Rechtliche Aspekte**************************************************/

/*#rechtliche_aspekte {
    overflow-x: hidden;

    h2 {
        color: #9e9e9e;
        padding: 30px 75px;
        font-size: 26px;

    }
    #rechtliches {
        padding: 0px 50px;
        h4 {
            font-weight: 700;
            font-size: 20px;
        }
        p {
            font-size: 20px;
            margin: 18px 0;
        }
        ul{
            margin-left: 32px;
            font-size: 18px;
        }
        a {
            text-decoration: none;
            color: #1a325b;
        }
    }
}*/

/***********Datenschutz********************************************************/

/*#datenschutz {

    #top {
        color: #9e9e9e;
        padding: 30px 75px;
        font-size: 26px;

    }
    p {
        font-size: 18px;
        margin: 10px 50px;
        text-decoration: none;
    }

    a {
        text-decoration: none;
        color: #1a325b;
    }
    h2 {
        padding: 0px 50px;
    }

    h3 {
        margin: 20px 50px;
    }
    ul {
        margin-left: 90px;
    }
}*/

/**********footer**************************************************************/
/*
footer {
    background: url("footer_bg2.svg");
    height: 310px;
    opacity: .9;
    position: relative;
    margin: 0 auto;




    #links_footer {
        position: relative;
        max-width: 1400px;
        margin: 0 auto;
        margin-top: 15px;
    }

    #cp_footer {
        position: relative;
        height: 76px;
        max-width: 1400px;
        margin: 0 auto;
        margin-top: 25px;

    }
    #links_mob_footer {
        display: none;
    }
    #cp_mob_footer {
        display: none;
    }

    #footer_text {
        position: absolute;
        top: 29px;
        left: 210px;
        color: #fff;
        width: calc(100% - 210px);

        #cp {
            float:right;
            position: relative;
            right: 50px;
        }
    }

    #logo_footer {
        width: 150px;
        position: absolute;
        top: 15px;
        left: 50px;

    }

    #company {
        display: inline-block;
    }

    table {
        width: 100%;
        position: relative;
        padding-top: 90px;
        padding: 90px 50px 0px;


        th{
            text-align: left;
            a {
                color: #9e9e9e;
                text-decoration: none;
                font-size: 16px;
                font-weight: 700;
                padding: 5px;
                padding-left: 0;
            }
        }
        td {
            text-align: left;
            font-size: 16px;
            padding: 5px;
            padding-left: 0;
            a {
                color: white;
                text-decoration: none;
                
                img {
                    width: 30px;
                    padding: 10px;
                }
            }

        }
    }
}

.borderWhite {
    margin-top: 15px;
    position: absolute;
    width: 100%;
    padding: 1em;
    background-image:
        radial-gradient(circle at 2.5px, #fff 1.25px, rgba(255,255,255,0) 2.5px),
        radial-gradient(circle, #fff 0px, rgba(255,255,255,0) 0px),
        radial-gradient(circle at 2.5px, #fff 1.25px, rgba(255,255,255,0) 2.5px),
        radial-gradient(circle, #fff 0px, rgba(255,255,255,0) 0px);
    background-position: top;
    background-size: 15px 5px, 5px 15px;
    background-repeat: repeat-x, repeat-y;    
}

#fbFooter {
    height: 30px;
    width: 30px;
    background-color: white;
    -webkit-mask: url(fb-logo.svg) no-repeat center;
    mask: url(fb-logo.svg) no-repeat center contain;
    mask-size: contain;
    display: inline-block;
    margin: 5px;
    
}

#lnFooter {
    height: 30px;
    width: 30px;
    background-color: white;
    -webkit-mask: url(ln-logo.svg) no-repeat center;
    mask: url(fb-logo.svg) no-repeat center contain;
    mask-size: contain;
    display: inline-block;
    margin: 5px;
}
*/
/**********Responsive Design***************************************************/
/* Media Queries: Phone Landscape */
/*@media screen and (max-width: 1275px) { 
    #bergportal_team {
        #personen {
            .person {
                max-width: 500px;
            }
        }
    }
    #pressespiegel {
        #artikels {
            .artikel {
                width: 45%;
            }
        }
    }
}

@media screen and (max-width: 1253px) { 
    #bergportal_team {
        #personen {
            .person {
                a {
                    display: block;
                    
                }
                .tel::before {
                    content: '\A';
                    display: block;
                }
            }
        }
    }
}

@media screen and (max-width: 1055px) { 
    #bergportal_team {
        #personen {
            .person {
                max-width: 400px;
                width: 40%;
            }
        }
    }

    #pressedownloads {
        #artikels {
            .artikel {
                width: 45%;
            }
        }
    }
}

@media screen and (max-width: 860px) {
    ******Portale****************
    portale
    #portale {
        #portals {
            .portal {
                width: 100%;
                margin: 10px auto;
            }
        }
    }
    news
    #news_part {
        #news {
            .news {
                width: 100%;
                margin: 10px auto;
            }
        }
    }



}

@media screen and (max-width: 855px) { 
    #bergportal_team {
        #personen {
            margin: 0;
            .person {
                max-width: 500px;
                margin: 10px auto;
            }
        }
    }
}



@media screen and (max-width: 833px) {
    footer {
        height: 383px;
    }
    #company {
        display: block !important;
    }

}

@media screen and (max-width: 825px) {

    Header
    h2 {
        text-align: center;

    }



    #datenschutz {
        h2 {
            text-align: left;
        }
        #top {
            text-align: center;
        }
    }

    #donations {
        right: 30px;
    }

    #burgermenu {
        display: block;
    }
    #line {
        display: none;
    }

    #donations {
        display: none;
    }

    main {
        margin-top: 110px;
    }


    header {
        height: 110px;

        #header_container {
            height: 110px;
            margin-top: 0;
        }
    }

    #nav_con {
        position: relative;
        display: none;
        background-color: unset;
        height: calc(110vh - 110px);
        overflow-y: auto;

        .SM {
            display: none;
        }
        #nav_inhalt {
            display: block;
            margin: 0;
            width: 100%;
            height: 650px;


            .nav_part {
                display: block;
                padding: 0;
                width: 100%;
                text-align: center;

                .nav_element {
                    padding: 0;
                    margin-top: 20px;
                    color: #9e9e9e;
                    font-size: 25px;
                }

                .submenu {
                    display: block;
                    position: relative;
                    background-color: unset;
                    width: 200px;
                    padding: 0 0 10px;
                    margin: 0 auto;
                    border: 0;
                    border-bottom: 4px dotted #9e9e9e;

                    .sub_nav_element {
                        display: block;
                        width: 100%;
                        margin-top: 15px;

                        a {
                            color: white;
                            font-size: 25px;
                        }
                    }
                }
            }

        }
    }
    #partner {
        #partners {

            .partner {
                width: 48%;
                margin: 15px 0;


            }
        }
    }
}

@media screen and (max-width: 765px) {
    footer {
        table td {
            font-size: 15px;
        }
        height: 310px;
    }
    #pressespiegel {
        #artikels {
            .artikel {
                width: 90%;
                margin: 10px auto;
            }
        }
    }
    #pressedownloads {
        #artikels {
            .artikel {
                width: 90%;
                margin: 10px auto;
            }
        }
    }
}

@media screen and (max-width: 681px) {
    footer {
        height: 353px;
    }
    #bergportal_team {
        h2 {
            padding-left: 0;
            padding-right: 0;
            margin-left: 0;
            margin-right: 0;
        }
        #personen {
            .person {
                max-width: 500px;
                margin: 10px auto;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 622px) {
    footer {
        height: 365px;
    }

    #partner {
        #partners {

            .partner {
                width: 100%;
                margin: 15px 0;


            }
        }
    }
}

@media screen and (max-width: 606px) {
    #carousel {
        .c_image {
            .layer {
                .layerText {
                    h1 {
                        font-size: 30px;
                    }
                    h2 {
                        font-size: 35px;
                    }
                    p {
                        font-size: 30px;
                    }
                    a {
                        font-size: 15px;
                    }

                }
            }
        }
    }

    footer {
        height: 340px;
        #links_footer {
            display: none;
        }
        #cp_footer {
            display: none;
        }
        #links_mob_footer {
            display: flex;
            position: relative;
            top: 80px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            width: 90%;
            margin: 0 auto;

            .flex {
                width: 45%;
                text-decoration: none;
                color: white;
                font-size: 17px;
                text-align: center;
                padding: 5px;
            }
        }
        #cp_mob_footer {
            display: block;
            border-top: 5px dotted white;
            top: 85px;
            position: relative;

            #logo_mob_footer {
                width: 150px;
                position: relative;
                top: 10px;
                left: 50%;
                transform: translateX(-50%);
            }
            p {
                color: white;
                margin: 5px;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 563px) {
    #partner {
        #partners {
            .partner {
                img {
                    display: block;
                    margin: 10px auto;
                }
                p {
                    display: block;
                    margin: 10px auto;
                    float: unset;
                    width: unset;
                }
            }
        }
    }
}

@media screen and (max-height: 430px) {

    #carousel {
        .c_image {
            .layer {
                .layerText {
                    h1 {
                        font-size: 15px;
                        padding: 5px;
                    }
                    h2 {
                        font-size: 25px;
                        padding: 5px;
                        letter-spacing: 5px;
                    }
                    p {
                        font-size: 15px;
                        padding: 5px;
                    }
                    a {
                        font-size: 10px;

                    }

                }
            }
        }
    }

}

@media screen and (max-width: 450px) {
    #carousel {
        height: calc(100vh - 110px);
        .c_image {
            .layer {
                .layerText {
                    h1 {
                        font-size: 25px;
                        padding: 10px;
                    }
                    h2 {
                        font-size: 50px;
                        padding: 10px;
                        letter-spacing: 5px;
                    }
                    p {
                        font-size: 25px;
                        padding: 10px;
                    }
                    a {
                        font-size: 20px;

                    }

                }
            }
        }

    }
    #partner {
        #partners {
            width: 100%;
            padding: 0;
        }
    }

    #portale {
        padding: 0;
        h2 {
            margin: 30px 0;
        }
        #portals {
            .portal {
                min-width: unset;
                width: 100%;
                padding: 10px;
                margin: 20px 0;
            }
        }
    }
    #news_part {
        padding: 0;

        h2 {
            margin: 30px 0;
        }
        #news {
            .news {
                width: 100%;
                min-width: unset;
                padding: 10px;
                margin: 20px 0;
            }
        }
    }
    #pressespiegel {
        h2 {
            margin: 0px;
            width: unset;
        }
        #artikels {
            margin: 0;
            .artikel {
                width: 100%;
            }
        }
    }
    #pressedownloads {
        h2 {
            margin: 20px;
            width: unset;
        }
        #artikels {
            margin: 0;
            .artikel {
                width: 100%;
            }
        }
    }
    #bergportal_team {
        h2 {
            margin: 20px;
            width: unset;
        }
        #personen {
            margin: 0;
            .person {

            }
        }
    }

    #newsletter {
        h2 {
            width: unset;
            margin: 20px;
        }
        #anmeldung {
            margin: 0;

            form {
                width: 100%;

                input[type="email"], input[type="text"] {
                    width: calc( 100% - 12px);
                }
                input[type="submit"] {
                    width: 40%;
                    left: 50%;
                    position: relative;
                    transform: translateX(-50%);
                }
            }
        }
    }
    #spenden {

        #danke {
            padding: 0 10px;
        }
        h2 {
            margin: 20px;
        }
        p {
            margin: 15px;
            padding: 0 15px;
        }
        #daten {
            margin: 60px 0;

            p {
                strong {
                    display: block;
                }
            }
        }
    }
    #legate {
        h2 {
            margin: 20px;
        }
        p {
            margin: 0px 10px;
        }
    }
    #rechtliche_aspekte {
        h2 {
            margin: 20px;
            width: unset;
        }
        #rechtliches {
            margin: 0 5px;
            padding: 0 25px;
        }
    }
    #impressum {
        h2 {
            margin: 20px;
            width: unset;
        }
        #imp {
            margin: 0 15px;
        }
    }

}

@media screen and (max-width: 420px) {
    #header_container {
        #logo_img {
            left: 30px;
        }
        #menubutton {
            right: 30px;
        }
    }   
    #nav_con {
        .SM {
            left: 30px;
        }
    }
    .banner_part {
        margin: 0 auto;
    }
}

@media screen and (max-width: 390px) {
    #header_container {

        #donations {
            display: none;
        }
    }  

    #carousel {
        .c_image {
            .layer {
                .layerText {
                    h1 {
                        font-size: 25px;
                    }
                    h2 {
                        font-size: 30px;
                    }
                    p {
                        font-size: 25px;
                    }
                }
            }
        }
    }
    #impressum {
        #imp {
            padding: 0;
        }
    }
}

@media screen and (max-height: 610px) {

    #carousel {
        .c_image {
            .layer {
                .layerText {
                    h1 {
                        font-size: 15px;
                        padding: 10px;
                    }
                    h2 {
                        font-size: 30px;
                        padding: 10px;
                        letter-spacing: 5px;
                    }
                    p {
                        font-size: 15px;
                        padding: 10px;
                    }
                    a {
                        font-size: 10px;

                    }

                }
            }
        }
    }

}

*/
