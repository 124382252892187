#pressedownloads {
    //display: flex;
    overflow-x: hidden;

    h2 {
        margin-top: 30px;

    }
    #artikels {

        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        flex-direction: row;


        .artikel {
            width: 29%;
            margin: 20px 0;
            padding: 10px 0;
            background: #f6f6f6;


            .infos {
                color: #797979;
                font-size: 17px;
                padding: 5px 0;
                padding-left: $space;
                overflow: hidden;
                text-overflow: "...";
                white-space: nowrap;
            }
            h3 {
                color: #000;
                margin: 0 $space;
                font-family: roboto, sans serif;
                font-weight: 500;
                margin-bottom: 10px;
                letter-spacing: 0.5px;
                line-height: 25px;
                font-size: 17px;
                word-break: break-word;
                

            }
/*            img {
                width: calc(100%-40px);
                margin: 0 20px;

            }*/
            
            .pressebild {
                width: calc(100%-40px);
                margin: 0 auto 10px;
                padding-top: calc(50% - 20px);
                /*padding-top: 50%;*/
                background-size: cover;
            }
            .desc {
                padding: 5px $space;
                font-size: 18px;
                max-height: inherit;
                transition:all 3s ease;
                line-height: 25px;
                
            }
            .fullText {
                transition:all 3s ease;
            }
            .desc_hidden {
                max-height: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                content: "";
                position:relative;
            }
            .desc_hidden:before {
                content:"";
                width:100%;
                height:100%; 
                position:absolute;
                left:0;
                top:0;
                background:linear-gradient(transparent 150px, #f6f6f6);
            }
            a {
                text-decoration: none;
                color: #1a325b;
                padding: 5px $space;
                font-size: 18px;
                overflow: hidden;
                /*max-height: 20px;*/
                display: inline-block;

                .pdf {
                    width: 17px;
                    margin-right: 5px;
                }
            }
            .more {
                cursor: pointer;
            }
            .notVisible {
                display: none;
            }
            .morebutton {
                
                display: block;
                cursor: pointer;
                transform: translateX(-50%);
                left: 50%;
                position: relative;
            }
            .more_button_img {
                width: 15px;
                display: inline-block;
                
            }
            .more_button_text {
                display: inline-block;
            }

        }        
    }
}

@media screen and (max-width: 1052px) {

    #pressedownloads {
        
        #artikels {
            
            .artikel {
                width: 48%;
            }
        }
    }
    
}

@media screen and (max-width: 651px) {

    #pressedownloads {
        
        #artikels {
            
            .artikel {
                width: 100%;
            }
        }
    }
    
}