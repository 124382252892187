#legate {
    overflow-x: hidden;
    margin-bottom: 20px;


    h2 {
        padding-top: 30px;
    }

    p {
        font-size: 18px;
        margin: 10px $space;


        strong {
            
            font-weight: 500;
        }
        a {
            text-decoration: none;
            color: #1a325b;
        }
    }
}
