header{
    height:165px;
    width:100%;
    background: url("topo_bg.svg");
    background-color: white;
    position:fixed;
    z-index: 50;
    top: 0;
}

.inner_nav {
    max-width: 1400px;
    margin: auto;
}

#header_top{
    height:100px;
    position:relative;
}

#overlay {
    display: none;
    background-color: rgba(19,50,94,.9);
    position: absolute;
    /*z-index: 40;*/
    height: calc(100vh - 100px);
    top: 100px;
    left: -20px;
    width: 100vw;

}

#logo{
    position: absolute;
    left: 0;
    top: 20px;
    width: 200px;
}

#donations{
    padding: 5px;
    border: 3px solid #003a5c;
    width: 90px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 33px;
    letter-spacing: 0.05em;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
    color: #003a5c;

    &:hover {
        background-color: rgba(164,163,163,.1);
    }
}

#burgermenu {
    display: none;
    #menubutton {

    }
    #menubutton {
        position: absolute;
        right:0px;
        cursor: pointer;
        z-index: 999;
        width:20px;
        height:25px;
        padding:16px;
        top:35px;
    }
    #menubutton span {
        display: block;
        position:absolute;
        height: 4.5px;
        width: 35px;
        background: #003a5c;
        opacity: 1;

    }
    #menubutton span {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: transform .25s ease-in-out, top .25s ease-in-out;
    }
    #menubutton.open span:nth-child(1),#menubutton.open span:nth-child(4) {
        width: 0px;
        top: 18px;
    }
    #menubutton.open span:nth-child(2)  {
        transform: rotate(45deg);
        width: 35px;

    }
    #menubutton.open span:nth-child(3) {
        transform: rotate(-45deg);
        width: 35px;

    }
    #menubutton span:nth-child(1) {
        top: 3px;
    }
    #menubutton span:nth-child(2),#menubutton span:nth-child(3) {
        top: 15px;
    }
    #menubutton span:nth-child(4) {
        top: 27px;
    }
}

@media screen and (max-width: 1475px) {
    #header_top {
        margin: 0 20px;
    }
    #navigation {
        .inner {
            margin: 0 20px;
        }
    }
}

@media screen and (max-width: 1000px) {

    header {
        height: 100px;
        #header_top {
            #donations {
                display: none;
            }
            #burgermenu {
                display: block;

            }

        }
        .dotted-line-blue {
            display: none;
        }
        #navigation {
            
            display: none;
            z-index: 99;
            opacity: 1;
            height: calc(100vh - 100px);
            
            .inner {
                margin: 0;
            }

            .socialmedia {
                display: none;
            }
            ul {
            
            overflow-y: scroll;
            height: calc(100vh - 100px);
            width: 100vw;
            left: -20px;
            
                li {
                    
                    &:nth-child(4) {
                        padding-bottom: 80px;
                    }
                    
                    width: 100%;
                    max-width: 280px;
                    display: block;
                    margin: 15px auto 0;
                    text-align: center;
                    float: none;
                    
                    .nav_element {
                        color: $color-midgrey !important;
                        font-size: 25px !important;
                        padding-top: 35px !important;
                        
                    }                    
                    
                    
                    ul {
                        visibility: visible;
                        width: 100%;
                        background: inherit;
                        position: relative;
                        
                        li {
                            
                            margin: 0;
                            a {
                                color: white !important;
                                font-weight: 300 !important;
                                font-size: 25px !important;
                                padding: 20px 0 !important;
                                
                            }
                        }
                    }
                    

                }

            }
        }
    }

}