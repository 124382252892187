#geschichte {
    overflow-x: hidden;

   
    margin-bottom: 30px;
    h2 {
        padding-top: 30px;

    }
    p {
        padding: 5px $space;
        font-size: 18px;
        strong {
            
            font-weight: 500;
        }
    }
}