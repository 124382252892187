#impressum {
    overflow-x: hidden;

    h2 {
        padding-top: 30px;

    }
    #imp {
        a {
            display: inline-block;
        }
        p {
            font-size: 18px;
            margin: 15px 0 ;
            padding: 0 $space;
            a {
                text-decoration: none;
                color: #1a325b;
            }
        }
        img {
            max-width: 300px;
            margin: 0 $space;
            width: 90%;
        }
        
        .part {
            p {
                margin: 5px 0;
            }
        }
        #digvis_logo {
            width: 130px;
        }
        
        .credits {
            
            margin: 0 $space;
            a {
                text-decoration: none;
                color: #1a325b;
            }
            
        }
    }
}

@media screen and (max-width: 1000px) {
    
}