#bergportal_team {
    overflow-x: hidden;
    h2 {
        padding-top: 30px;

    }
    #personen {

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .person {
            width: 30%;
            margin: 10px 0;
            padding: 10px 0;
            background-color: #f6f6f6;
            h3 {
                color: #000;
                margin-left: $space;
                font-family: roboto, sans serif;
                font-weight: 500;
                margin-bottom: 10px;
                display: inline-block;
                font-size: 18px;
            }
            .lna {
                float: right;
                padding: 0 10px;
                font-size: 25px;
            }
            .img {
                width: 100%;
                position: relative;
                img {
                    width: 100%;
                }
                .layerP {
                    position: absolute; 
                    z-index: 5;
                    background-color: RGBA(0, 0, 0, 0.3);
                    width: 100%;
                    height: calc(100% - 5px);
                    display: none;
                    p {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: white;
                        font-size: 20px;
                        width: 80%;
                        text-align: center;
                    }
                }
                &:hover {
                    .layerP {
                        display: block;
                    }
                }

            }
            .pos {
                color: #9f9f9f;
                padding: 5px $space;
                font-size: 18px;
            }
            .contact {
                margin-left: $space;
            }
            a {
                text-decoration: none;
                color: #1a325b;
                
                font-size: 18px;
                
                .lnTeam {
                    height: 25px;
                    width: 25px;
                    background-color: #13325e;
                    -webkit-mask: url(ln-logo.svg) no-repeat center;
                    mask: url(fb-logo.svg) no-repeat center contain;
                    mask-size: contain;
                    display: inline-block;
                    
                }
            }
            .tel::before {
                content: " | ";
           
            }
        }        
    }
}

@media screen and (max-width: 1340px) {
    
    #bergportal_team {
        #personen {
            .person {
                .contact {
                    .tel {
                        display: block;
                        
                        &::before {
                            content: "";
                        }
                    }
                }
            }
        }
    }
    
}

@media screen and (max-width: 1100px) {
        #bergportal_team {
            #personen {
                .person {
                    width: 48%;
                }
            }
        }
}

@media screen and (max-width: 670px) {
        #bergportal_team {
            #personen {
                .person {
                    h3 {
                        font-size: 17px;
                    }
                }
            }
        }
}

@media screen and (max-width: 642px) {
        #bergportal_team {
            #personen {
                .person {
                    width: 100%;
                    h3 {
                        font-size: 18px;
                    }
                }
            }
        }
}

@media screen and (max-width: 432px) {
        #bergportal_team {
            #personen {
                .person {
                    h3 {
                        font-size: 17px;
                    }
                }
            }
        }
}

@media screen and (max-width: 329px) {
        #bergportal_team {
            #personen {
                .person {
                    h3 {
                        font-size: 16px;
                    }
                }
            }
        }
}