
////////////////////////////////////////////////////////////////////////////////
// Colors
////////////////////////////////////////////////////////////////////////////////

$color-black:                   #000000 !default;

$color-yellow:                  #f4c51a !default;
$color-lightgrey:               #e5e5e5 !default;
$color-midgrey:                 #9e9e9e !default;
$color-darkgrey:                #444444 !default;
$color-blue:                    #003a5c !default;
$color-white:                   #ffffff !default;

////////////////////////////////////////////////////////////////////////////////
// Sizes
////////////////////////////////////////////////////////////////////////////////

$space:                         20px;