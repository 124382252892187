#spenden {
    overflow-x: hidden;
    margin-bottom: 30px;

    h2 {
        padding-top: 30px;

    }
    #danke {
        font-family: roboto, sans serif;
        font-weight: 500;
        font-size: 20px;
        padding: 0 $space;
    }
    p {
        font-size: 18px;
        padding: 0px $space;

    }
    #daten {
        margin-top: $space;
        background: rgba(164, 163, 163, 0.1);
        padding: $space;

        p {
            margin: 10px 0;
            padding: 0;
        }
        strong {
            font-family: roboto, sans serif;
            font-weight: 500;
        }

    }
}

@media screen and (max-width: 1000px) {
    #spenden {
        #daten {
            strong {
                &::before {
                    content: "\a";
                    white-space: pre;
                }
            }
        }
    }
}