*{
    padding:0;
    margin:0;
    font-family: roboto, sans serif;
    font-weight: 300;

}
body{
    background: url("topo_bg.svg");
    overflow-x: hidden;
    background-attachment: fixed;
}
.dotted-line-blue {
    width: 100%;
    padding: 5px;
    margin-bottom: -5px;

    background-image: radial-gradient(circle at 2px, $color-blue 1.25px, rgba(255,255,255,0) 3px);
    background-position: top;
    background-size: 15px 5px, 5px 15px;
    background-repeat: repeat-x, repeat-y;      
}

.dotted-line-grey {
    padding: 5px;
    margin-bottom: -5px;
    
    background-image: radial-gradient(circle at 2px, $color-midgrey 1.25px, rgba(255,255,255,0) 3px);
    background-position: top;
    background-size: 15px 5px, 5px 15px;
    background-repeat: repeat-x, repeat-y;
    max-width: unset;    
}

.dotted-line-white {
    padding: 5px;
    margin-bottom: -5px;
    
    background-image: radial-gradient(circle, #fff 2px, rgba(255,255,255,0) 3px);
    background-position: top;
    background-size: 15px 5px;
    background-repeat: repeat-x;    
}
.inner{
/*    max-width:1400px;
    margin: auto;*/
}
h2 {
    color: $color-midgrey;
    margin-bottom: 30px;
    font-size: 26px;
    padding-left: 20px;
    font-weight: 300;
}
.fr{
    float:right;
}
.fl{
    float: left;
}
.mr{
    margin-right: $space;
}

.invisible {
    opacity: 0;

}

.bold {
    font-family: roboto, sans serif;
}


.overflowHidden {
    overflow: hidden;
}

section {
    max-width: 1400px;
    margin: auto;
}

@media screen and (max-width: 1475px) {
    #header_top {
        margin: 0 20px;
    }
    section {
        margin: 0 20px;
    }
}

@media screen and (max-width: 1000px) {
    .dotted-line-grey-mobile {
    
    padding: 5px;
    margin-bottom: -5px;
    
    background-image: radial-gradient(circle at 2px, $color-midgrey 1.25px, rgba(255,255,255,0) 3px);
    background-position: top;
    background-size: 15px 5px, 5px 15px;
    background-repeat: repeat-x, repeat-y;    
    }
}

/*@media screen and (max-width: 770px) {
    h2 {
        padding: 0;
        text-align: center;
    }
}*/